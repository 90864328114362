import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'node_modules/ngx-clipboard';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchText: string = '';
  type: string = '';
  loggedIn =  false;
  data:any = [];
  item:any = {};
  itemType:any = {};
  message = '';
  error = '';
  redirectTo = '';
  constructor(public cs: CommonService, private location: Location, public http: HttpClient
    , public dialog: MatDialog, private router: Router, private _snackBar: MatSnackBar
    , private route: ActivatedRoute, private _clipboardService: ClipboardService) {
    if (this.cs.user.access_token) {
      this.loggedIn = true;
    }
  }

  ngOnInit() {
    this.redirectTo = this.cs.redirectTo;
    this.cs.redirectTo = '';
    this.route.paramMap.subscribe((params: any) => {
      this.searchText = '';
      if(params != null && params.params != null && params.params.type != null){
        this.type= params.params.type.toLowerCase();
      }
      if(params != null && params.params != null && params.params.keyword != null){
        this.searchText= params.params.keyword.toLowerCase();
      }
      this.searchData();
    });
  }

  showDetails(item){
    this.clearMessageAndError();
    this.itemType = this.cs.enumTable.SearchType[item.Type];
    this.cs.request('/public/' + (this.cs.enumTable.SearchType[item.Type].Value).toLowerCase() + '?id=' + item.InternalId, 'get').then((res: any) => {
      if (res.Status == 200) {
        this.item = res.Data;
        this.item.DescriptionFormatted = this.item.Description.replace(/\n/g, '<br/>')
        if(this.redirectTo != null && this.redirectTo == 'search/event/' + this.data[0].InternalId ){
          //Auto rsvp
          this.update();
        }
      } else {
        this.error = res.Message;
      }
      this.redirectTo = '';
    }).catch((err) => {
      this.redirectTo = '';
    });
  }

  update(){
    this.clearMessageAndError();
    this.cs.request('/public/' + this.itemType.Value.toLowerCase(), 'post', this.item).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }

  clearData(){
    this.item = {};
    this.itemType = {};
    this.data = [];
  }

  share(item){
    var url = 'https://info.rishicabin.org/api/public/detail/' + this.itemType.Value.toLowerCase() + '/' + item.Id;

    this._clipboardService.copy(url);
    this._snackBar.open("Copied to Clipboard", "", {
      duration: 2000,
    });
  }

  search(){
    this.type = '';
    this.searchData();
  }

  searchData(){
    this.clearData();
    this.clearMessageAndError();
    var param = '';
    if(this.searchText != null && this.searchText != ''){
      param = 'keyword=' + this.searchText;
    }
    if(this.type != null && this.type != ''){
      if(param != null && param != ''){
        param = param + '&';
      }
      param = param + 'type=' + this.type;
    }
    this.cs.request('/public/search?' + param, 'get').then((res: any) => {
      if (res.Status == 200) {
        this.data = res.Data;
        if(this.type != null && this.type != '' && (this.type == 'event' || this.type == 'blog') && this.data != null && this.data.length == 1){
          this.showDetails(this.data[0]);
        }
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }

  clearMessageAndError(){
    this.error = '';
    this.message = '';
  }
}
