import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from '../common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  selectedService : any;

  constructor(private fb: FormBuilder, public cs: CommonService, private route: ActivatedRoute, private router: Router, private location: Location) { }

  ngOnInit() {
  }

  startConsultationProcess(skill){
    localStorage.clear();
    this.router.navigateByUrl('/consultation/'+ skill.Id);
  }

}
