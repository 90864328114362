import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  commandMap: Map<string, string> = new Map();
  keepMenu = true;
  isTopMenuCollasped = false;
  menuCommand = 'personal';
  menuCommandDescription = '';
  enableEdit = true;
  message = '';
  error = '';
  data : any= {};
  constructor(private fb: FormBuilder, public cs: CommonService, 
    private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar, 
    public breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      '(max-width: 768px)'
        ]).subscribe(result => {
          if (result.matches) {
            this.keepMenu = false;
          } else {
            this.keepMenu = true;
          }
    });   
  }

  ngOnInit() {
    if(this.cs.user.Id == null){
      this.router.navigateByUrl('/');
      return;
    }
    this.cs.spinner = false;
    this.readData();

    this.commandMap.set('personal', 'Personal Details');
    this.commandMap.set('address', 'Address');
    this.commandMap.set('professions', 'Profession');
    if(this.cs.IsVolunteer){
      this.commandMap.set('skills', 'Skills');
    }
    if(this.cs.IsSysAdmin){
      this.commandMap.set('skilldefinition', 'Skill Definition');
    }

    this.commandMap.set('about', 'About');

    this.menuCommandDescription = this.commandMap.get(this.menuCommand);
  }
  topMenuClicked(){
    this.isTopMenuCollasped = !this.isTopMenuCollasped;
  }

  menuItemClicked (cmd){
    this.clearMessageAndError();
    this.menuCommand = cmd.toLowerCase();
    this.menuCommandDescription = this.commandMap.get(this.menuCommand);
    this.isTopMenuCollasped = true;
    this.enableEdit = true;
    if (!this.cs.user.access_token){
        this.cs.loginSignupRedirect = 'settings/' + cmd;
        this.router.navigateByUrl('/login');
        return;
    }
  }

  addItemClicked (cmd){
    this.menuCommand = cmd.toLowerCase();
    if(this.menuCommand == 'skilldefinition'){
      this.cs.Skills.unshift({Id:0, Name:'', Category:'',Description:'', enableEdit:true})
    }
  }  

  deleteItemClicked (cmd, id){
    this.menuCommand = cmd.toLowerCase();
    var itemList = [];
    for (var i = 0; i < itemList.length; i++) {
      if(itemList[i].Id == id){
        itemList.splice(i, 1);
        break;
      }
    }
  }  

  handleAddressChange(event, addressParam) {
    var street_number = '';
    var route = '';

    addressParam.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        addressParam.City = address.short_name
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        addressParam.State = address.short_name
      } else if (address.types.indexOf('postal_code') != -1) {
        addressParam.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        addressParam.Country = address.short_name;
      }
    }
    addressParam.Street = street_number + ' ' + route;
  }

  verifyEmail(){
    this.clearMessageAndError();
    this.cs.request('/applicationuser/verifyemail', 'post').then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  verifyPhone(){
    this.clearMessageAndError();
    this.cs.request('/applicationuser/verifyphone', 'post').then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  updateData(type){
    var data = this.data;
    this.clearMessageAndError();
    if(type == 'skilldefinition'){
      data = this.cs.Skills;
    }
    if(type == 'skills'){
      this.data.Skills = this.cs.Skills.filter(item => item.Selected == true);
    }
    this.cs.request('/applicationuser/' + type, 'post', data).then((res: any) => {
      if (res.Status == 200) {
        if(this.menuCommand == 'skilldefinition'){
          this.cs.readEnums();
        }
        this.message = res.Message;
        if(res.Logout == null || res.Logout == false){
          this.readData();
        }
        else{
          this.message = "";
          this.error = res.Message;
        }
        this.edit(false);
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  save(){
    this.updateData(this.menuCommand);
  }

  cancel(){
    if(this.menuCommand == 'skilldefinition'){
      this.cs.readEnums();
    }
    this.clearMessageAndError();
    this.edit(false);
    this.readData();
  }

  clearMessageAndError(){
    this.error = '';
    this.message = '';
  }

  readData(){
    this.cs.request('/applicationuser', 'get').then((res: any) => {
      if (res.Status == 200) {
        this.data = res.Data;
        this.data.IsVolunteer = this.cs.IsVolunteer;
        this.data.DateOfBirthDisplay = new Date(this.data.DOB);
        this.processSkills();
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }
  edit(flag){
    this.enableEdit = flag;
  }
  editItem(item, flag){
    item.enableEdit = flag;
  }

  showSnackBar(text){
    this._snackBar.open(text, 'Undo', {
    });
  }

  processSkills(){
    var vskills = this.data.Skills.map(function(element){
      return element.Id;
    });

    for(var i=0; i<this.cs.Skills.length; i++){
      this.cs.Skills[i].Selected = false;
    }
    
    var vskills = this.cs.Skills.filter(item => vskills.includes(item.Id));

    if(vskills.length > 0){
      for(var i=0; i<vskills.length; i++){
        vskills[i].Selected = true;
      }
    }
  }
}
