import { ExtraOptions, RouterModule, Routes, CanActivate, Router } from '@angular/router';
import { NgModule, Injectable } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { ContactComponent } from './contact/contact.component';
import { CommonService } from './common.service';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SettingComponent } from './setting/setting.component';
import { SearchComponent } from './search/search.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ServiceComponent } from './service/service.component';
import { AboutComponent } from './about/about.component';
import { CalendarComponent } from './calendar/calendar.component';
import { BadgeComponent } from './badge/badge.component';

@Injectable()
export class CanActivateLogin implements CanActivate {
    constructor(private cs: CommonService, private router: Router) { }

    canActivate() {
        if (this.cs.user.access_token)
            this.router.navigateByUrl('/');
        return this.cs.user.access_token ? false : true
    }
}

const routes: Routes = [
    {
        path: 'unsubscribe',
        component: UnsubscribeComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent
    },
    {
        path: 'search',
        component: SearchComponent
    },
    {
        path: 'search/:type',
        component: SearchComponent
    },    
    {
        path: 'search/:type/:keyword',
        component: SearchComponent
    },
    {
        path: 'setting',
        component: SettingComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [CanActivateLogin]
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'services',
        component: ServiceComponent
    },
    {
        path: '',
        component: MainComponent
    },
    {
        path: 'consultation/:id',
        component: MainComponent
    },
    {
        path: 'about',
        component: AboutComponent
    },
    {
        path: 'calendar',
        component: CalendarComponent
    },
    {
        path: 'badge',
        component: BadgeComponent
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];
const config: ExtraOptions = {
    useHash: false,
    onSameUrlNavigation: 'reload'
};
@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}