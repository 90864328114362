import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from '../common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

declare var $: any, google: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
  private _map = null;
  consultationform: FormGroup;
  getFormError = getFormError;
  message = '';
  error = '';
  eventData : any= [];
  blogs : any= [];
  showMoreBlogs: boolean = false;
  upcomingEvents : any= [];
  memberUpcomingEvents : any= [];
  chapterUpcomingEvents : any= [];
  regionalUpcomingEvents : any= [];
  globalUpcomingEvents : any= [];
  pastEvents : any= [];
  showMoreEvents: boolean = false;
  skills : any= [];
  todayDate=new Date();
  showConfirmation = false;

  shoqQRCode = false;

  contentData : any= [];
  constructor(private fb: FormBuilder, public cs: CommonService, private route: ActivatedRoute, private router: Router, private location: Location) {
    this.route.paramMap.subscribe((params: any) => {
      if(params != null && params.params != null && params.params.id != null && params.params.id != ''){
        var selectedCategoryId = params.params.id.toLowerCase();
        if(selectedCategoryId != null && selectedCategoryId > 0){
          //reload enums
          this.cs.readEnums().then((result: any) => {
            var selectedSkill = this.cs.Skills.filter(item => item.Id == selectedCategoryId);
            if(selectedSkill != null && selectedSkill.length > 0){
              //update drop downs
              this.consultationform.controls.Category.setValue(selectedSkill[0].Category);
              this.categoryChange(selectedSkill[0].Category);
              this.consultationform.controls.RequestedSkill.setValue(selectedSkill[0].Id)
            }

          }).catch((err) => {
          });
        }
      }
    });
  }

  ngOnInit() {    

    this.cs.spinner = false;
    this.showConfirmation = false;
    this.readData();

    this.consultationform = this.fb.group({
      Name: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Description: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      RequestedDateTime: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Category: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      RequestedSkill: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      FirstName: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Email: ['', [Validators.required, Validators.email, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      PhoneNumber: ['', [Validators.required, Validators.pattern("[0-9]{10}")]]
    });
  }

  categoryChange(categoryName){
    if(categoryName == null){
      let data = this.consultationform.getRawValue();
      categoryName = data.Category;
    }
    this.skills = this.cs.Skills.filter(item => item.Category == categoryName );
    this.location.replaceState('/');
  }

  skillChange(categoryName){
    let data = this.consultationform.getRawValue();
    var skillId = data.RequestedSkill;
    this.location.replaceState('/consultation/' + skillId.toString());
  }

  ngAfterViewInit() {
    if(this._map == null)
    {
      var mapDiv = document.createElement('div');
      this._map = new google.maps.Map(mapDiv);      
    }
  }

  consultationsubmit(){
    if (this.consultationform.invalid) {
      this.consultationform.markAllAsTouched();
      return;
    }
    let data = this.consultationform.getRawValue();

    this.cs.request('/public/consult', 'post', data).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
        this.showConfirmation = true;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }
  continue(){
    this.ngOnInit();
    this.router.navigateByUrl('/');
  }

  qrCode(){
    this.shoqQRCode = !this.shoqQRCode;
  }

  readData(){
    this.cs.request('/public/events', 'get').then((res: any) => {
      if (res.Status == 200) {
        var currentDate = new Date();
        this.eventData = res.Data;
        this.upcomingEvents = this.eventData.filter(item => new Date(item.EventDateTime) >= currentDate );
        this.memberUpcomingEvents = this.eventData.filter(item => this.cs.enumTable.EventType[item.Type].Value == 'Member' && new Date(item.EventDateTime) >= currentDate );
        this.chapterUpcomingEvents = this.eventData.filter(item => this.cs.enumTable.EventType[item.Type].Value == 'Chapter' && new Date(item.EventDateTime) >= currentDate );
        this.regionalUpcomingEvents = this.eventData.filter(item => this.cs.enumTable.EventType[item.Type].Value == 'Regional' && new Date(item.EventDateTime) >= currentDate );
        this.globalUpcomingEvents = this.eventData.filter(item => this.cs.enumTable.EventType[item.Type].Value == 'Global' && new Date(item.EventDateTime) >= currentDate );

        this.pastEvents = this.eventData.filter(item => new Date(item.EventDateTime) < currentDate ).sort(function(a, b) {
          if( new Date(b.EventDateTime) >= new Date(a.EventDateTime)) return 1;
          return -1;
        });
        if(this.pastEvents.length > 3){
          this.showMoreEvents = true;
          this.pastEvents = this.pastEvents.slice(0, 3);
        }
        this.cs.request('/public/contentsbylocation?location=0', 'get').then((res: any) => {
          if (res.Status == 200) {
            this.contentData = res.Data;
          } else {
            this.error = res.Message;
          }
        }).catch((err) => {
        });
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });

    this.cs.request('/public/blogs', 'get').then((res: any) => {
      if (res.Status == 200) {
        this.blogs = res.Data;
        if(this.blogs.length > 3){
          this.showMoreBlogs = true;
          this.blogs = this.blogs.slice(0, 3);
        }

      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }
}
