import { Component, OnInit} from '@angular/core';
import { CommonService } from '../common.service';
import { FormBuilder,} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { ClipboardService } from 'node_modules/ngx-clipboard';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  constructor(private fb: FormBuilder, public cs: CommonService, 
    private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar, private _clipboardService: ClipboardService) { }

  ngOnInit() {
    if(this.cs.user.Id == null){
      this.router.navigateByUrl('/');
      return;
    }
    this.cs.readData();
  }

}
