import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { CommonService } from '../common.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
declare var $: any, CryptoJS: any, window: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  tab = 'login';
  loginform: FormGroup;
  signupform: FormGroup;
  getFormError = getFormError;
  goBackAllowed = false;
  showForgotPassword = false;
  requestError = '';
  requestMessage = '';
  cameFrom = '';
  productId = '';
  LogoutOtherLogins : boolean = false;
  constructor(private fb: FormBuilder, public cs: CommonService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.cs.spinner = false;

    if(this.route.snapshot != null && this.route.snapshot.queryParams != null && this.route.snapshot.queryParams.token != null
      && this.route.snapshot.queryParams.token != ''){
      this.showForgotPassword = true;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tab = window.history.state.page || 'login';
      }
    })
    if ($.fn.fullpage.destroy)
      $.fn.fullpage.destroy('all');
    this.route.paramMap
      .pipe(map(() => window.history.state))
    this.tab = window.history.state.page || 'signup';
    if (window.history.state.backTo) {
      this.goBackAllowed = true;
      this.cameFrom = window.history.state.cameFrom || 'service';

      if (window.history.state.productId) {
        this.productId = window.history.state.productId;
      }

    }
    if (localStorage.getItem('productId')) {
      this.productId = localStorage.getItem('productId');
    }
    this.loginform = this.fb.group({
      Email: ['', [Validators.required, emailPhoneUseridValidator, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      password: ['', [Validators.required]]
    });

    this.signupform = this.fb.group({
      FirstName: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Email: ['', [Validators.required, Validators.email, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      PhoneNumber: ['', [Validators.required, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      password: ['', [Validators.required]],
      ConfirmPassword: ['', [Validators.required]],
      TermsAndCondition: ['', [Validators.required]],
      Volunteer: ['', []],
    });
  }




  showLogin(e) {
    this.showForgotPassword = e;
  }

  termsAndConditionAccepted(){
    this.cs.TermsAndConditionFlag = true;
    document.cookie = "TermsAndConditionFlag=" + this.cs.TermsAndConditionFlag.toString();             
    this.handleRedirection();
  }

  updateLogoutOtherLogins(){
    this.LogoutOtherLogins = !this.LogoutOtherLogins;
  }

  loginsubmit() {

    this.requestError = '';
    this.requestMessage = '';

    this.requestError = '';
    //this.requestMessage = '';
    if (this.loginform.invalid) {
      this.loginform.markAllAsTouched();
      return;
    }
    let data = this.loginform.getRawValue();
    data.LoginDevice = 0; //0:Web, 1: App
    data.LogoutOtherLogins = this.LogoutOtherLogins;
    var model ={Username: data.Email, Password: data.password}
    this.cs.login(model).then((resLogin: any) => {
      if(resLogin.Status == 200){
        this.requestMessage = resLogin.Message + '... please wait';
        this.cs.loginSuccess(resLogin);
      }else{
        this.requestError = resLogin.Message;
      }
    }).catch((err) => {
      this.requestError = err.statusText;
    })
  }

  signupsubmit() {

    this.requestError = '';
    this.requestMessage = '';

    let signupformdata = this.signupform.getRawValue();

    if (this.signupform.invalid) {
      this.signupform.markAllAsTouched();
      return;
    }

    if(signupformdata.Volunteer == null || signupformdata.Volunteer == ''){
      signupformdata.Volunteer = false;
    }

    if (signupformdata.password != signupformdata.ConfirmPassword) {
      $('.isamepassword').removeClass('hide');
      return;
    } else {
      $('.isamepassword').addClass('hide');
    }
    
    signupformdata.Password = signupformdata.password;
    delete signupformdata.password;
    this.cs.request('/applicationuser/register', 'post', signupformdata).then((res: any) => {
      if (res.Status == 200) {
        this.requestMessage = res.Message + '... please wait';
        this.cs.loginSuccess(res);
      } else {
        this.requestError = res.Message;
      }
    }).catch((err) => {
    })
  }


  changeTab(tab) {
    this.tab = tab;
    if (this.tab == 'login') {
      this.signupform.reset();
    } else {
      this.loginform.reset();
    }
    this.requestError = '';
  }

  handleRedirection() {
    var loginSignupRedirect = this.cs.loginSignupRedirect;
    if(this.cs.loginSignupRedirect != 'addresses'){
      this.cs.loginSignupRedirect = '';
    }
    if ((window.history.state.backTo && window.history.state.productId) || localStorage.getItem('productId')) {
      this.router.navigateByUrl('/product/' + this.productId);
    } else if (window.history.state.backTo && window.history.state.bookmarks) {
      this.router.navigateByUrl('/network');
    } else {
      if (localStorage.getItem("preFilters")) {
        localStorage.setItem("filters", localStorage.getItem("preFilters"));
        this.router.navigateByUrl('/products');
      } else {
        if (window.history.state.backTo) {
          if (this.cameFrom == 'product') {
            this.router.navigateByUrl('/payment');
          } else {
            this.router.navigateByUrl('/servicerequest', { state: { step: 5 } });
          }
        } else {
          this.router.navigateByUrl('/' + loginSignupRedirect);
        }
      }
    }


  }

  goBack() {
    if (this.cameFrom == 'product') {
      this.router.navigateByUrl('/product/' + window.history.state.productId);
    } else {
      this.router.navigateByUrl('/servicerequest', { state: { step: 4 } });
    }
  }

}
